<template>
    <div class="container dirr" style="display: block; padding-top:50px; max-width: 1261px ;" v-if="$i18n.locale === 'ar'">

        <div>
            <v-layout row>
                
                <v-flex sm6 xs12 md6 lg4 >
                    <router-link :to="'/new/photo?lang=ar'" style="text-decoration: none;color: #00112C;" >
                        <div class="ma-4">
                            <div class="fill-heigh articles_cat"  :style="$vuetify.theme.dark ? 'background: #474747' : 'background: #F5F5F5'">
                                <div class="art_cat_icon">
                                    <img src="/assets/img/open-book 1.png">
                                </div>
                                <div class="art_cat_title" :style="$vuetify.theme.dark ? 'color: #FFF' : ''">{{ $t("Picture_Reports") }}</div>
                            </div>
                        </div>
                    </router-link>
                    
                    <v-card  class="my-6 pa-2 ma-2 inf" >
                        <template v-for="(card, cardIndex) in cardd" >
                            <div class="video_wrapper" v-if="card.video_file">
                                <router-link  :to="'/new/infograph_inner/'+ card.id + '?&lang=' +$i18n.locale">
                                    <video @mouseenter="playVideo" @mouseleave="pauseVideo" @touchstart="playVideo" @touchend="pauseVideo" muted :poster="'https://alkafeel.net/news/images/main/370/' + card.image" :src="'https://alkafeel.net/news/images/infograph/'+card.video_file" class="image-hover-scale full-height-img video-container" ></video>
                                </router-link>
                                <button v-if="!isPlaying" class="play-btn" @click="togglePlay">
                                    <span class="play-icon">{{ isPlaying ? '❚❚' : '▶' }}</span>
                                </button>
                            </div>
                            
                            <v-carousel v-else :cycle="true" interval="3000" :show-arrows="false">
                                <v-carousel-item v-for="(image, i) in [card.image, ...card.images]" :key="i">
                                    <router-link :to="'/new/infograph_inner/'+ card.id + '?&lang=' +$i18n.locale" class="item">
                                        <img :src="image.includes('http') ? image : 'https://alkafeel.net/news/images/main/370/' + image" class="image-hover-scale full-height-img">
                                    </router-link>
                                </v-carousel-item>
                            </v-carousel>
                        </template>
                        
                    </v-card>
                </v-flex>
            


<v-flex sm6 xs12 md6 lg4 v-for="(d, i) in c.slice(0,1)" :key="i" >
                    <router-link :to="'/new/'+cats[i].url+'?lang='+$i18n.locale" style="text-decoration: none;color: #00112C;" >
                        <div class="ma-4">
                            <div class="fill-heigh articles_cat"  :style="$vuetify.theme.dark ? 'background: #474747' : 'background: #F5F5F5'">
                                <div class="art_cat_icon">
                                    <img src="/assets/img/open-book 1.png">
                                </div>
                                <div class="art_cat_title" :style="$vuetify.theme.dark ? 'color: #FFF' : ''">{{ cats[i].title }}</div>
                            </div>
                        </div>
                    </router-link>


                    <v-skeleton-loader v-if="isLoading" class="border"  max-width="800" type="image, article" ></v-skeleton-loader>
                    
                    <v-card v-if="res[d]" height="518px" :loading="loading" class="my-6 pa-2 ma-2" >
                        <v-img height="250" :src="'https://alkafeel.net/news/mcroped96/625/'+res[d].image" ></v-img>
                        <v-card-title class="ctitle"> {{ res[d].title }} </v-card-title>
                        <v-card-text class="ctext"  v-html="filterBBCode(res[d].text)"></v-card-text>
                        <v-divider class="mx-4"></v-divider>
                        <v-card-actions>
                            <div class="mr-auto cfooter">
                                <router-link :to="'/new/'+res[d].id+'?lang='+$i18n.locale" style="text-decoration: none;color: #00112C;" :style="$vuetify.theme.dark ? 'color: #FFF' : ''">{{ $t('more') }}</router-link>
                            </div>
                        </v-card-actions>
                    </v-card>
                    
                </v-flex>


              
                    <v-flex sm6 xs12 md6 lg4 >

                        <a :href="'https://newspaperstories.alkafeel.net'" target="_blank" style="text-decoration: none;color: #00112C;" >
                        <div class="ma-4">
                            <div class="fill-heigh articles_cat"  :style="$vuetify.theme.dark ? 'background: #474747' : 'background: #F5F5F5'">
                                <div class="art_cat_icon">
                                    <img src="/assets/img/open-book 1.png">
                                </div>
                                <div class="art_cat_title" :style="$vuetify.theme.dark ? 'color: #FFF' : ''">قصص خبرية</div>
                            </div>
                        </div>
                    </a>


                        <!-- <v-skeleton-loader v-if="isLoading" class="border"  max-width="800" type="image, article" ></v-skeleton-loader> -->
                    <v-card  height="518px" :loading="loading" class="my-6 pa-2 ma-2" >
                        <v-img height="250" :src="'https://newspaperstories.alkafeel.net/uploads/article/thumb/'+storenews.image" ></v-img>
                        <v-card-title class="ctitle"> {{ storenews.title }} </v-card-title>
                        <v-card-text class="ctext"  
                        
                           v-html="$options.filters.subStrArtTitlesSec(storenews.description)"
                        ></v-card-text>
                    
                        <v-divider class="mx-4"></v-divider>
                        <v-card-actions>
                            <div class="mr-auto cfooter">
                                <a :href="'https://newspaperstories.alkafeel.net/content/show/'+storenews.id" style="text-decoration: none;color: #00112C;" :style="$vuetify.theme.dark ? 'color: #FFF' : ''">{{ $t('more') }}</a>
                            </div>
                        </v-card-actions>
                    </v-card>
                </v-flex>



            </v-layout>
        </div>
    </div>
</template>



<script>

// {title:this.$t("articles"), url:'article'},  'art',
// {title:this.$t("Picture_Reports"), url:'photo'},  'inf', 
export default {
    name: 'carrousel',
    data() {
        return {
            c:['adeb', 'rep'],
            res:[],
            cards: [],
            storenews:'',
            cats:[{title:this.$t("adeb"), url:'literature'}, {title:this.$t("news_reports"), url:'reports'}],
            isPlaying: false,
            isLoading: true,

        }
    },
    
    computed: {
        swiper() {return this.$refs.mySwiper.$swiper},
    },
    mounted() {
    },
    created () {
        this.ini()
        this.grtpicNews()
        this.grtstorenews()
    },
    filters: {

        subStrArtTitlesSec(value) {
    if (!value) return '';

    // Remove HTML tags
    let text = value.replace(/<[^>]+>/g, ' ');

    // Normalize spaces (convert multiple spaces & newlines to a single space)
     text.replace(/\s+/g, ' ').trim();
    return text.substring(0, 1075) + ' ... ';
  }
    },
    methods:{
        filterBBCode(text) {
            // return text;
            const bbcodeRegex = /\[\/?[^\]\[]+\]/g;
            return text.replace(bbcodeRegex, '');
        },
        ini () {
            this.$axios.get("articles/Getmainart")
            .then(data => {
                this.isLoading = false;
                this.res = data.data;
            })
        },
        grtpicNews () {
            this.$axios.get("articles/GetInfograph/1/0", {
            })
            .then(res => {
                this.cardd = res.data.data;
            })
        },

        grtstorenews () {
            this.$axios.get("https://alkafeel.net/alkafeel_back_test/api/v1/story_competition", {
            })
            .then(res => {
                this.storenews = res.data;
            })
        },
        //https://newspaperstories.alkafeel.net/api/alkafeel_home
        playVideo(event) {
            const video = event.target;
            video.play();
            this.isPlaying = true; // Update state to playing
        },
        pauseVideo(event) {
            const video = event.target;
            video.pause();
            this.isPlaying = false; // Update state to paused
        },
        togglePlay(event) {
            const video = event.target.closest('.video-wrapper').querySelector('video');
            if (video.paused) {
            video.play();
            this.isPlaying = true; // Update state to playing
            } else {
            video.pause();
            this.isPlaying = false; // Update state to paused
            }
        },
        updatePlayIcon(event) {
            const video = event.target;
            this.isPlaying = !video.paused; // Sync state with video's playback state
        },
    },
}
</script> 
<style>

.full-height-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
}
.video-container {
  width: 100%;
  height: 100%;
  object-fit: contain; 
}
.full-height-img:hover {
    /* transform: scale(1.05); */
}


.video_wrapper {
  position: relative;
  display: inline-block;
}

.play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  color: white;
  font-size: 28px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
}

.play-btn:hover {
  background: rgba(0, 0, 0, 0.9);
}

.play-icon {
  margin-left: 4px;
}





.inf .v-carousel {
    overflow:visible
}
.inf .v-carousel__controls .v-icon {
    font-size: 8px;
}
.inf .v-carousel__controls {
    position: relative;
    background: transparent;
}
.inf .mdi-circle::before {
    content: ""!important;
}
.inf .v-carousel__controls__item.v-btn.v-btn--icon {
    background-color: #000; 
    width: 10px;
    height: 10px;
}
  
.inf .v-carousel__controls__item.v-btn.v-btn--icon.v-btn--active {
    background-color: #cac2c2; 
}

.inf .v-carousel__controls__item.v-btn.v-btn--icon:hover {
    background-color: black; 
    
}
</style>
<style scoped>
    @font-face {
        font-family: 'readex';
        src: url("/assets/fonts/ReadexPro/ReadexPro-Regular.ttf") format("truetype");
    }
    @font-face {
        font-family: 'readex_bold';
        src: url("/assets/fonts/ReadexPro/ReadexPro-Bold.ttf") format("truetype");
    }
    .ctitle {
        font-family: 'readex_bold';
        font-size: 14px;
        word-break:keep-all;
        height: 64px;
    }
    .cfooter a{
        font-family: 'readex';
        font-size: 14px;
    }
    .ctext {
        font-family: 'readex';
        font-size: 13px;
        height: 145px;
    }
    .desc2 {
        box-shadow: 0px 4px 4px 0px #00000014;
        background-color: white;
        border-radius: 8px;
        padding: 15px;
    }
    .sec_discrib {
        font-family: 'readex';
        margin: 0 15px;
    }
    .title2 {
        display:ruby
    }
    .title2 .tl {
        font-family: 'readex_bold';
        font-size: 42px;
        /* color:#000; */
        padding: 5px 15px;
        text-decoration: none;
    }
    .spacer {
        width: 4px;
        height: 30px;
        margin: 0 15px;
        background: #B1BD52;
    }
    .art_cat_icon {
        width: 46px;
        height: 46px;
        background: #adadad;
        border-radius: 50%;
        text-align: center;
        display: inline-block;
        margin-left: 15px;
        margin-right: 15px;
        vertical-align: middle;
    }
    .art_cat_title {
        display: inline-block;
        font-size: 16px;
        font-family: 'readex';
    }
    .art_cat_icon img {
        margin-top: 15px;
        width: 18px
    }
    .articles_cat {
        background: #F5F5F5;
        /* width: 100%; */
        height: 87px;
        
        align-content: center;
        text-align: start;
        padding-inline-start: 20px;
    }
    .articles_cat div {
        
    }
    .swiper-pagination-bullet-active {
        background: #000!important;
    }
    .pro_img {
        margin: 15% auto 5px;
        width: 56px;
    }
    .pro_cat_text {
        text-decoration: unset;
        font-family: 'readex';
        color: #000;
    }
    .pro_cat {
        min-height: 170px;
        vertical-align: middle;
        text-align: center;
        border: #ddd solid thin;
        box-shadow: 22px 40px 40px 0px #0000000D;
        margin: 10px;
        min-width: 200px;
        background-color: #FFF;
    }
    .swiper {
        width: 100%;
        height: 100%;
    }
    .swiper-slide {
        text-align: center;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .swiper-slide img {
        max-height: 210px;
    }
    .height {
        width: 100%;
        height: 100%;
    }
    .gradiant_img {
        background-image: linear-gradient(to top, #0C0F12 0%, transparent 82px);
    }
    .sw_img_date {
        color: white;
        font-family: 'readex';
        font-size: 14px;
        text-decoration: none;
        position: absolute;
        bottom: 8px;
        left: 15px;
    }
    .sw_img_cat {
        color: black;
        font-family: 'readex';
        font-size: 12px;
        font-weight: 400;
        position: absolute;
        bottom: 6px;
        background: #FFF;
        padding: 5px;
        margin-left: 6px;
        margin-right: 6px;
        border-radius: 3px;

    }
    .sw_img_title {
        color: white;
        font-family: 'readex';
        font-size: 20px;
        font-weight: 400;
        position: absolute;
        bottom: 50px;
        left:0;
        right:0;
        text-align: center;
        text-shadow: 1px 1px 2px #000;
    }
    .sw_item {
        vertical-align: bottom;
        border-radius: 5px;
    }
</style>